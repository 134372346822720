import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { loginUser } from '../../../_actions/user_action';
import { withRouter } from 'react-router-dom';
import Swal from 'sweetalert2';

function LoginPage(props) {
    const dispatch = useDispatch();

    const [Email, setEmail] = useState('');
    const [Password, setPassword] = useState('');

    const onEmailHandler = (event) => {
        setEmail(event.currentTarget.value);
    };

    const onPasswordHandler = (event) => {
        setPassword(event.currentTarget.value);
    };

    const onSubmitHandler = (event) => {
        event.preventDefault();

        let body = {
            email: Email,
            password: Password,
        };

        const form = document.getElementsByName('form1')[0];
        if (form.reportValidity()) {
            dispatch(loginUser(body)).then((response) => {
                if (response.payload.loginSuccess) {
                    // console.log('로그인ID: ' + response.payload.loginID);
                    // console.log('사용자토큰: ' + response.payload.userToken);
                    // console.log('로그인API: ' + response.payload.key);
                    // console.log('사용자시크릿: ' + response.payload.secret);
                    // console.log('프록시URL: ' + response.payload.proxyurl)';
                    localStorage.setItem(
                        'userInfo',
                        JSON.stringify({
                            userkey: response.payload.userkey,
                            id: response.payload.loginID,
                            token: response.payload.userToken,
                            expiry: response.payload.expiry,
                            key: response.payload.key,
                            secret: response.payload.secret,
                            proxyurl: response.payload.proxyurl,
                        })
                    );
                    props.history.push('/on');
                } else {
                    //alert(response.payload.message);
                    Swal.fire({
                        html: `${response.payload.message}`,
                    });
                }
            });
        }
    };

    return (
        <>
            <div
                className="frame-2-Ix1nMc"
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100vh',
                }}
            >
                <form
                    className="frame-3 screen"
                    name="form1"
                    action="form1"
                    onSubmit={onSubmitHandler}
                >
                    <div className="overlap-group-j45Jnv">
                        <div className="rectangle-wDOxsz"></div>
                        <div className="rectangle-gB1Lxv">
                            <input
                                className="id-4vxZMW"
                                name="id"
                                placeholder="아이디 입력"
                                type="email"
                                value={Email}
                                onChange={onEmailHandler}
                                required
                            />
                        </div>
                        <div className="rectangle-4iU3ck">
                            <input
                                className="password-B3S2bM"
                                name="password"
                                placeholder="비밀번호 입력"
                                type="password"
                                value={Password}
                                onChange={onPasswordHandler}
                                required
                            />
                        </div>
                        <div className="login-wDOxsz">Login</div>
                        <p className="please-check-th-ou-are-visiting-wDOxsz">
                            Please check that you are visiting
                        </p>
                        <div className="httpswwwtradingkitio-wDOxsz">
                            https://www.tradingkit.io
                        </div>
                        <div className="username-email-wDOxsz">
                            Username (Email)
                        </div>
                        <div className="password-wDOxsz">Password</div>
                        <div className="forgot-password-wDOxsz">
                            Forgot Password?
                        </div>
                        <button type="submit">
                            <div className="group-3-wDOxsz">
                                <div className="rectangle-PSBNBQ"></div>
                                <div className="login-PSBNBQ">Login</div>
                            </div>
                        </button>
                        <img
                            className="x-wDOxsz"
                            src="img/-------------------1@2x.png"
                            alt="profile"
                        />
                    </div>
                </form>
            </div>
        </>
    );
}

export default withRouter(LoginPage);
