import { LOGIN_USER, REGISTER_USER, AUTH_USER } from '../_actions/types';

export default function reducers(state = {}, action) {
    let value = {};
    switch (action.type) {
        case LOGIN_USER:
            value = { ...state, loginSuccess: action.payload };
            break;
        case REGISTER_USER:
            value = { ...state, register: action.payload };
            break;
        case AUTH_USER:
            value = { ...state, userData: action.payload };
            break;
        default:
            value = state;
    }
    return value;
}
