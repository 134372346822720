import React, { useEffect } from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';

const PROXY =
    window.location.hostname === 'localhost'
        ? 'http://localhost:5000'
        : 'https://tradingkit-ba39c1659b44.herokuapp.com';

function LandingPage(props) {
    useEffect(() => {
        axios.get(`${PROXY}/api/hello`).then((response) => {
            if (response.data.success) {
                alert('정상적으로 연결되었습니다.');
            } else {
                alert('api/hello 수행결과: 서버와의 연결이 실패했습니다.');
            }
        });
    }, []);

    const onClickHandler = () => {
        axios.get(`${PROXY}/api/users/logout`).then((response) => {
            if (response.data.success) {
                props.history.push('/login');
            } else {
                alert('로그아웃 하는데 실패 했습니다.');
            }
        });
    };

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '100vh',
            }}
        >
            <h2>시작 페이지</h2>

            <button onClick={onClickHandler}>로그아웃</button>
        </div>
    );
}

export default withRouter(LandingPage);
