import axios from 'axios';
import { LOGIN_USER, REGISTER_USER, AUTH_USER } from './types';

const PROXY =
    window.location.hostname === 'localhost'
        ? 'http://localhost:5000'
        : 'https://tradingkit-ba39c1659b44.herokuapp.com';

export function loginUser(dataToSubmit) {
    const request = axios
        .post(`${PROXY}/api/users/login`, dataToSubmit)
        .then((response) => response.data); //이부분은 promise라 수정하면 payload에 유저정보가 실리지 않는다. 그대로 유지한다.
    return {
        type: LOGIN_USER,
        payload: request,
    };
}

export function registerUser(dataToSubmit) {
    const request = axios
        .post(`${PROXY}/api/users/register`, dataToSubmit)
        .then((response) => response.data);
    return {
        type: REGISTER_USER,
        payload: request,
    };
}

export function auth() {
    const request = axios
        .get(`${PROXY}/api/users/auth`)
        .then((response) => response.data);
    return {
        type: AUTH_USER,
        payload: request,
    };
}
